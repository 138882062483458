$(document).ready(function(){
                $('.coluna-portifolio').slick({
                  dots: false,
                  infinite: true,
                  speed: 500,
                  fade: true,
                  cssEase: 'linear',
                  autoplay: true,
                  autoplaySpeed: 2700
              });        
            });
function fnBaseController($scope, $http) {

    $("#msgAguardandoEmail").hide();
    
    $scope.$watch(function() {
        
    })

    var nivelMedidor = 50;

    $scope.validaEmail = function () {
        if ($scope.usuario.emailUsuario != '') {
            $http.post('/cadastrousuario/validaemail', $scope.usuario)
                .then(function success(dados) {dados = dados.data;
                    if (dados.estado == 'indisponivel') {
                        angular.element(".erro-emailindisponivel").show();
                        $scope.usuario.emailValido = 'invalido';
                    } else {
                        angular.element(".erro-emailindisponivel").hide();
                        $scope.usuario.emailValido = 'valido';
                    }
                });
        }
    }
    angular.element(".erro-emailindisponivel").hide();

    $scope.validaLogin = function () {
        if ($scope.usuario.loginUsuario != '') {
            $http.post('/cadastrousuario/validalogin', $scope.usuario).then(function success(dados) {
                dados = dados.data;
                if (dados.estado == 'disponivel') {
                    angular.element(".erro-loginindisponivel").hide();
                    angular.element(".sucesso-logindisponivel").show();
                    $scope.usuario.loginValido = 'valido';
                } else if (dados.estado == 'indisponivel') {
                    angular.element(".sucesso-logindisponivel").hide();
                    angular.element(".erro-loginindisponivel").show();
                    $scope.usuario.loginValido = 'invalido';
                }
            });
        }
    }
    angular.element(".erro-loginindisponivel").hide();
    angular.element(".sucesso-logindisponivel").hide();
    
    angular.element(".erro-senhainvalida").hide();

    $scope.registraUsuario = function () {
        if ($scope.formCadastro.$valid) {

            angular.element("#btnCadastraUsuario").removeClass('fa-save');
            angular.element("#btnCadastraUsuario").addClass('fa-cog fa-spin');

            var erro = 0;

            angular.element(".erro-loginindisponivel").hide();
            angular.element(".sucesso-logindisponivel").hide();

            if ($scope.nivelMedidor < nivelMedidor && $scope.usuario.senhaUsuario.length < 8 && $scope.usuario.senhaUsuario.length > 0) {
                angular.element(".erro-senhainvalida").show();
                erro++;
            } else {
                angular.element(".erro-senhainvalida").hide();
            }

            if ($scope.usuario.emailValido == 'invalido') {
                angular.element(".erro-emailindisponivel").show();
                erro++;
            }

            if ($scope.usuario.loginValido == 'invalido') {
                angular.element(".erro-loginindisponivel").show();
                erro++;
            }

            if (erro == 0) {
                $http.post('/cadastrousuario', $scope.usuario)
                    .then(function success(dados) {
                        dados = dados.data;
                        angular.element("#btnCadastraUsuario").removeClass('fa-cog fa-spin');
                        angular.element("#btnCadastraUsuario").addClass('fa-save');

                        angular.element("#avisoCadastroErro").hide();
                        angular.element("#avisoCadastroSucesso").show();
                        $scope.usuario = [];
                        angular.element("#medidor-senha").find(".progress-bar").css({'width': '0'});
                        angular.element("form[name=formCadastro]").validator('destroy');
                    }, function error(dados) {
                        showErrorWhoops(dados.data);
                    })
            }
        }
    }

    angular.element('#mdReenviaDados').on('hidden.bs.modal', function (e) {
        $("#emailReenviaDados").val('');
    });

    $scope.reenviaConfirmacao = function () {
        if ($scope.formReenviaConfirmacao.$valid) {

            angular.element("#iconeReenviaConfirmacao").removeClass('fa-send');
            angular.element("#iconeReenviaConfirmacao").addClass('fa-cog fa-spin');
            $("#msgAguardandoEmail").show();

            $http.post('/cadastrousuario/reenvia', $scope.reenvia)

                .then(function success(dados) {
                    dados = dados.data;
                    if (dados.estado == 'sucesso') {
                        $("#msgAguardandoEmail").hide();
                        angular.element(".aviso-oculto").hide();
                        angular.element("#avisoReenvioSucesso").show();
                        angular.element("#btnReenviaConfirmacao").hide();
                        angular.element("#btnReenviaConfirmacaoOk").show();
                        $scope.reenvia = [];
                        angular.element("form[name=formReenviaConfirmacao]").validator('destroy');
                    }

                    if (dados.estado == 'inexistente') {
                        angular.element(".aviso-oculto").hide();
                        angular.element("#avisoReenvioInexistente").show();
                    }
                    
                    angular.element("#iconeReenviaConfirmacao").removeClass('fa-cog fa-spin');
                    angular.element("#iconeReenviaConfirmacao").addClass('fa-send');
                }, function error(dados) {
                    dados = dados.data;
                    angular.element(".aviso-oculto").hide();
                    angular.element("#avisoReenvioErro").show();
                    
                    angular.element("#iconeReenviaConfirmacao").removeClass('fa-cog fa-spin');
                    angular.element("#iconeReenviaConfirmacao").addClass('fa-send');
                })
        }
    }

    $scope.entrarUsuario = function () {
        if ($scope.formEntrar.$valid) {
            angular.element('input').attr('disabled', 'disabled');
            angular.element('#btnEntrar').html('<i class="fa fa-cog fa-spin"></i> Logando...');

            $http.post('/entrar', $scope.entrar)

                .then(function success(dados) {
                    dados = dados.data;
                    if (dados.estado == 'sucesso') {
                        var url = "/cadastro/cliente/list";
                        angular.element(window.document.location).attr('href', url);
                    }

                    if (dados.estado == 'erro') {

                        angular.element('#btnEntrar').html('Entrar');
                        angular.element("#avisoEntrarErroTexto").html(dados.mensagem);
                        angular.element("#avisoEntrarErro").show();
                        angular.element('input').removeAttr('disabled');
                    }
                }, function error(dados) {
                    angular.element('#btnEntrar').html('Entrar');
                    angular.element("#avisoEntrarErroTexto").html('Sessão expirada, por favor recarregue a página e tente novamente.');
                    angular.element("#avisoEntrarErro").show();
                    angular.element('input').removeAttr('disabled');
                });
        }
    }

    $scope.reenviarDados = function () {
        if ($scope.formReenviaDados.$invalid) {
            return false;
        }

        angular.element("#iconeBtnReenviaDados").removeClass('fa-send');
        angular.element("#iconeBtnReenviaDados").addClass('fa-cog fa-spin');
        angular.element(".aviso-oculto").hide();

        $http.post('/entrar/reenviar', $scope.reenvia)

            .then(function success(dados) {
                dados = dados.data;
                angular.element("#avisoReenviaSucessoTexto").html(dados.mensagem);
                angular.element("#avisoReenviaSucesso").show();
                angular.element("#iconeBtnReenviaDados").removeClass('fa-cog fa-spin');
                angular.element("#iconeBtnReenviaDados").addClass('fa-send');

            }, function error(dados) {
                dados = dados.data;
                var mensagem = dados.mensagem;
                if (dados.mensagem == undefined) {
                    mensagem = 'Erro';
                }
                angular.element("#avisoReenviaErroTexto").html(mensagem);
                angular.element("#avisoReenviaErro").show();
                angular.element("#iconeBtnReenviaDados").removeClass('fa-cog fa-spin');
                angular.element("#iconeBtnReenviaDados").addClass('fa-send');
            });
    }

    $scope.gravaNovaSenha = function () {

        angular.element(".aviso-oculto").hide();

        if (angular.element(".campo-nivelMedidor").val() < nivelMedidor && $scope.novaSenha.senhaUsuario.length < 8) {
            angular.element("#erro-senhainvalida").show();
            return false;
        }

        $http.post('/entrar/novasenha', $scope.novaSenha)

            .then(function success(dados) {
                dados = dados.data;
                if (dados.estado == 'sucesso') {
                    angular.element("#avisoNovaSenhaSucessoTexto").html(dados.mensagem);
                    angular.element("#avisoNovaSenhaSucesso").show();
                    $scope.novaSenha = [];
                    angular.element("#medidor-senha").find(".progress-bar").css({'width': '0'});
                    return false;
                }

                if (dados.estado == 'erro') {
                    angular.element("#avisoNovaSenhaErroTexto").html(dados.mensagem);
                    angular.element("#avisoNovaSenhaErro").show();
                    return false;
                }

            }, function error(dados) {
            dados = dados.data;
            var mensagem = dados.mensagem;
            if (dados.mensagem == undefined) {
                mensagem = 'Erro';
            }
            angular.element("#avisoNovaSenhaErroTexto").html(mensagem);
            angular.element("#avisoNovaSenhaErro").show();
        });
    }
    
    $scope.isEmailLoginValido = function() {
        return $scope.usuario.loginValido == 'valido' && $scope.usuario.emailValido == 'valido'
    }
    
}
fnBaseController.$inject = ['$scope', '$http'];
angular.module('app').controller('baseController', fnBaseController);
