function fnMedidorSenhaController($scope) {

	$scope.nivelMedidor = 0;

	$scope.medidorSenha = function(keyCode){
		var valor = angular.element(".campo-senha").val();
		var barra = $("#medidor-senha");
		var barraStatus = barra.find(".progress-bar");
		var barraDesc = barra.find("small");
		var medidor = Number(0);

		if(valor != ''){

			for (var i = 0; i < valor.length; i++) {
				var especiais = ['!','@','#','$','%','&','*','+','_','-'];
				var numero = /[0-9]/g;
				var letra = /[a-z]/g;
				var letraMaiuscula = /[A-Z]/g;

				if( especiais.indexOf(valor[i]) >= 0 ) medidor += 30;
				if( numero.test(valor[i]) ) medidor += 10;
				if( letraMaiuscula.test(valor[i]) ) medidor += 20;
				if( letra.test(valor[i]) ) medidor += 10;
			}

			$("#valor-medidor").val(medidor);
			barraStatus.removeClass('progress-bar-success');
			barraStatus.removeClass('progress-bar-warning');
			barraStatus.removeClass('progress-bar-danger');

			if( medidor >= 150 ){
				barraStatus.css({'width':'100%'});
				barraDesc.html( $("#medidor-ideal").val() );
				$scope.nivelMedidor = 100;
				return false;
			}

			if( medidor >= 130 ){
				barraStatus.css({'width':'75%'});
				barraStatus.addClass('progress-bar-success');
				barraDesc.html( $("#medidor-forte").val() );
				$scope.nivelMedidor = 75;
				return false;
			}

			if( medidor >= 100 ){
				barraStatus.css({'width':'60%'});
				barraStatus.addClass('progress-bar-warning');
				barraDesc.html( $("#medidor-medio").val() );
				$scope.nivelMedidor = 60;
				return false;
			}

			if( medidor >= 80 ){
				barraStatus.css({'width':'50%'});
				barraStatus.addClass('progress-bar-warning');
				barraDesc.html( $("#medidor-medio").val() );
				$scope.nivelMedidor = 50;
				return false;
			}

			if( medidor >= 40 ){
				barraStatus.css({'width':'25%'});
				barraStatus.addClass('progress-bar-danger');
				barraDesc.html( $("#medidor-fraco").val() );
				$scope.nivelMedidor = 25;
				return false;
			}

			if( medidor >= 30 ){
				barraStatus.css({'width':'5%'});
				barraStatus.addClass('progress-bar-danger');
				barraDesc.html("");
				$scope.nivelMedidor = 5;
				return false;
			}

		} else {
			barraStatus.css({'width':'0%'});
			barraDesc.html("");
		}

	}
}
fnMedidorSenhaController.$inject = ['$scope'];
angular.module('app').controller('medidorSenhaController', fnMedidorSenhaController);